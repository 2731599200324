import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import './index.css';
import { msalInstance } from './security/msalInstanceHelper';

ReactDOM.render(
  <React.StrictMode>
        <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

