import {
  DetailsList,
  DetailsListLayoutMode,
  FontSizes,
  IColumn,
  SelectionMode,
  FontIcon,
  CommandBar,
  Dialog,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  DialogType,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BotTestFileDetails } from "../../models/BotTestFileDetails";
import {
  TestExecutionResult,
  TestState,
} from "../../models/TestExecutionResult";
import BotTestService from "../../services/BotTestService";
import { formatDate } from "../../utils";
import { BotTestFileUploadDialog } from "./components/BotTestFileUploadModal";
import { BotTestHistory } from "./components/BotTestHistory";

export const BotTest: React.FunctionComponent = () => {
  const [selectedItem, setSelectedItem] = useState<BotTestFileDetails>();
  const [showHistory, setShowHistory] = useState(false);
  const [testExecutionResults, setTestExecutionResults] = useState<
    TestExecutionResult[]
  >([]);

  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [files, setFiles] = useState<BotTestFileDetails[]>([]);

  const [hideDeleteFileModal, setHideDeleteFileModal] = useState(true);
  const [hideRunTestFileModal, setHideRunTestFileModal] = useState(true);

  const [testFileLoading,setTestFileLoading] = useState(false);
  const [testHistoryLoading,setTestHistoryLoading] = useState(false);
  const [testFileIsEnqueued,setTestFileIsEnqueued] = useState(false);

  const [selectedFileId,setSelectedFileId] = useState<string>("");

  const params = new URLSearchParams(window.location.search);
  const botId = params.get("id") || "";
  const botName = params.get("name") || "";

  const columns: IColumn[] = [
    {
      key: "icon",
      name: "icon",
      iconName: "",
      isIconOnly: true,
      fieldName: "icon",
      minWidth: 32,
      maxWidth: 32,
      onRender: (item: BotTestFileDetails) =>
      item.id === selectedItem?.id ? (
          <FontIcon
            style={{ color: "#007bff", fontSize: "15px" }}
            aria-label="MSNVideosSolid"
            iconName="BoxCheckmarkSolid"
          />
        ) : (
         <span></span>
        ),
    },
    {
      key: "fileName",
      name: "File Name",
      minWidth: 300,
      maxWidth: 300,
      fieldName: "fileName",
      onRender: (item: BotTestFileDetails) => (
        item.id === selectedItem?.id ? (
          <span style={{fontWeight:"bold"}} >{item.fileName}</span>
          ) :  <span>{item.fileName}</span>
      ),
    },
    {
      key: "totalUtterances",
      name: "# Utterances",
      minWidth: 100,
      maxWidth: 100,
      fieldName: "totalUtterances",
      onRender: (item: BotTestFileDetails) => (
        item.id === selectedItem?.id ? (
          <span style={{fontWeight:"bold"}} >{item.totalUtterances}</span>
          ) :  <span>{item.totalUtterances}</span>
      ),
    },
    {
      key: "uploadDate",
      name: "Upload Date",
      minWidth: 150,
      maxWidth: 150,
      fieldName: "uploadDate",
      onRender: (item: BotTestFileDetails) => (
        item.id === selectedItem?.id ? (
        <span style={{fontWeight:"bold"}} >{formatDate(new Date(item.uploadDate))}</span>
        ) :  <span>{formatDate(new Date(item.uploadDate))}</span>
      ),


      
    },
    {
      key: "run",
      name: "Run",
      iconName: "",
      isIconOnly: false,
      fieldName: "icon",
      minWidth: 50,
      maxWidth: 50,
      onRender: (item: BotTestFileDetails) => (
        <FontIcon
          style={{
            color: "#007bff",
            fontSize: "16px",
            cursor: "pointer",
            paddingLeft: "10px",
          }}
          aria-label="Rocket"
          iconName="Rocket"
          onClick={() => {
            setSelectedItem(item);
            setHideRunTestFileModal(false);
          }}
        />
      ),
    },
    {
      key: "history",
      name: "History",
      iconName: "",
      isIconOnly: false,
      fieldName: "icon",
      minWidth: 50,
      maxWidth: 50,

      onRender: (item: BotTestFileDetails) => (
        <FontIcon
          style={{
            color: "#007bff",
            fontSize: "16px",
            cursor: "pointer",
            paddingLeft: "10px",
          }}
          onClick={() => {
            setSelectedItem(item);
            setShowHistory(true);
            getTestsHistory(item.id, true);
          }}
          aria-label="History"
          iconName="History"
        />
      ),
    },
    {
      key: "export",
      name: "Export",
      iconName: "",
      isIconOnly: false,
      fieldName: "icon",
      minWidth: 50,
      maxWidth: 50,
      onRender: (item: BotTestFileDetails) => (
        <FontIcon
          style={{
            color: "#007bff",
            fontSize: "16px",
            cursor: "pointer",
            paddingLeft: "10px",
          }}
          aria-label="DownloadDocument"
          iconName="DownloadDocument"
          onClick={() => {
            setSelectedItem(item);
            window.open(
              `${process.env.REACT_APP_API_BASE_URL}/api/BotTest/files/${item.id}`,
              "_blank"
            );
          }}
        />
      ),
    },

    {
      key: "delete",
      name: "Delete",
      isIconOnly: false,
      fieldName: "icon",
      minWidth: 50,
      maxWidth: 50,
      onRender: (item: any) => (
        <FontIcon
          style={{
            color: "#007bff",
            fontSize: "16px",
            cursor: "pointer",
            paddingLeft: "10px",
          }}
          aria-label="RecycleBin"
          iconName="RecycleBin"
          onClick={() => {
            setSelectedItem(item);
            setHideDeleteFileModal(false);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    setTestFileLoading(true);
    BotTestService.getTestFiles(botId || "")
      .then((result) => {
        setTestFileLoading(false);
        setFiles(result);
      })
      .catch((error) => {
        setTestFileLoading(false);
        toast.error("An error occurred while fetching test files");
      });
  }, []);

  const getFileIndex = (fileId: string) => {
    return files.findIndex((file) => file.id === fileId);
  }

  const getTestsHistory = (fileName: string, enablePolling: boolean) => {

    setTestHistoryLoading(true);
    BotTestService.getTestsHistory(botId || "", fileName)
      .then((result) => {
        setTestExecutionResults(result);
        setTestHistoryLoading(false);
        setShowHistory(true);
      })
      .catch((error) => {
        setTestHistoryLoading(false);
        toast.error("An error occurred while fetching test history");
      });
   

    if (enablePolling) {
      let intervalId = setInterval(() => {
        BotTestService.getTestsHistory(botId || "", fileName)
          .then((result) => {
            setTestExecutionResults(result);
            setShowHistory(true);
            let runningTestExecs = result.filter(
              (x) => x.status === TestState.ASSIGNED || x.status === TestState.RUNNING
            );

            if (runningTestExecs.length === 0) {
              clearInterval(intervalId);
            }
          })
          .catch((error) => {
            clearInterval(intervalId);
            toast.error("An error occurred while fetching test history");
          });
      }, 3000);
    } 
    
  };

  const deleteTestFile = (testFileId: string) => {
    BotTestService.deleteTestFile(botId || "", testFileId)
      .then((result) => {
        toast.success("Test file deleted successfully");
        setFiles(files.filter((file) => file.id !== testFileId));
      })
      .catch((error) => {
        toast.error("An error occurred while fetching test history");
      });
  };

  const queueTest = (botId: string, testFileName: string) => {

    setTestFileIsEnqueued(true);
    //Api logic goes here
    BotTestService.queueTest(botId, testFileName)
      .then(() => {
        // let queuedTest: TestExecutionResult = {
        //   executionDate: new Date().toLocaleString(),
        //   fileName: testFileName,
        //   id: "",
        //   status: TestState.NotStarted,
        //   passedUtterances: 0,
        //   successRate: 0,
        //   totalUtterances: 0,
        // };

        // testExecutionResults.push(queuedTest);
        // setTestExecutionResults([queuedTest, ...testExecutionResults]);

        // setShowHistory(true);

        // toast.success("Test queued successfully");
        setTestFileIsEnqueued(false);
        setHideRunTestFileModal(true);
        getTestsHistory(testFileName, true);
      })
      .catch((error) => {
        toast.error("An error occurred while queueing test");
      });
  };

  return (
    <div className="container">
      <span style={{ fontSize: FontSizes.size32 }}>{botName}</span>

      <CommandBar
        items={[
          {
            key: "new",
            name: "Upload new file",
            iconProps: {
              iconName: "CloudUpload",
            },
            disabled: false,
            onClick: () => {
              setShowUploadDialog(true);
            },
          },
        ]}
        ariaLabel="Inbox actions"
        primaryGroupAriaLabel="Email actions"
        farItemsGroupAriaLabel="More actions"
      />

      <div className="row">
        <div className="col-md-12">
          {testFileLoading && (
            <Spinner size={SpinnerSize.large} label="Loading, please wait" />
          )}

          {!testFileLoading && (
            <DetailsList
              items={files}
              columns={columns}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onActiveItemChanged={(item: any,index?: number,ev?: React.FocusEvent<HTMLElement>) => {
                console.log(item.id);
                //setSelectedFileId(item.id);
              }
            }

            />
          )}

          <br />

          {showHistory && testHistoryLoading && (
            <Spinner size={SpinnerSize.large} label="Loading, please wait" />
          )}

          {showHistory && !testHistoryLoading && (
            <BotTestHistory items={testExecutionResults} />
          )}

          {showUploadDialog && (
            <BotTestFileUploadDialog
              botName={botId || ""}
              onClose={() => setShowUploadDialog(false)}
              onUpload={(
                result: boolean,
                fileDetail: BotTestFileDetails | null
              ) => {
                if (result) {
                  files.push(fileDetail!);
                  setFiles(files);
                  toast.success("File successfully uploaded!");
                } else {
                  toast.error("An error occured while uploading the file!");
                }
                setShowUploadDialog(false);
              }}
            />
          )}
        </div>
      </div>

      <Dialog
        hidden={hideDeleteFileModal}
        onDismiss={() => setHideDeleteFileModal(true)}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Are you sure you want to delete this test file?",
        }}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              deleteTestFile(selectedItem!.id);
              setHideDeleteFileModal(true);
            }}
            text="Yes"
          />
          <DefaultButton
            onClick={() => {
              setHideDeleteFileModal(true);
            }}
            text="No"
          />
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={hideRunTestFileModal}
        onDismiss={() => setHideRunTestFileModal(true)}
        dialogContentProps={{
          type: DialogType.normal,
          title: testFileIsEnqueued ? "Starting test 🚀, please wait...": "Are you sure you want to run this test file?"
        }}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              queueTest(botId, selectedItem!.id);
            }}
            text="Yes"
          />
          <DefaultButton
            onClick={() => setHideRunTestFileModal(true)}
            text="No"
          />
        </DialogFooter>
      </Dialog>
    </div>
  );
};
