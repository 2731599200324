import axios from "axios";
import { BotConfig } from "../models/BotConfig";
import { BotTestFileDetails } from "../models/BotTestFileDetails";
import { TestExecutionResult } from "../models/TestExecutionResult";

export class BotTestService {
  private apiBasePath: string = process.env.REACT_APP_API_BASE_URL || "";

  async getBotsConfigs(): Promise<BotConfig[]> {
    var response = await axios.get(`${this.apiBasePath}/api/bottest/botConfigurations`);
    return response.data;
  }

  async uploadTestFile(botName:string,file: FormData): Promise<BotTestFileDetails> {
    var response = await axios.post(
      `${this.apiBasePath}/api/bottest/upload?botName=${botName}`,
      file,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  }

  async deleteTestFile(botName:string,testFileId: string): Promise<boolean> {
    var response = await axios.delete(
      `${this.apiBasePath}/api/bottest?botName=${botName}&testFileId=${testFileId}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  }

  async getTestFiles(botName:string): Promise<BotTestFileDetails[]> {
    var response = await axios.get(`${this.apiBasePath}/api/bottest/files?botName=${botName}`);
    return response.data;
  }

  async getTestsHistory(botName:string,fileNameId:string): Promise<TestExecutionResult[]> {
    var response = await axios.get(`${this.apiBasePath}/api/bottest/history?botName=${botName}&testFileId=${fileNameId}`);
    return response.data;
  }

  async queueTest(botId:string,testFileId:string): Promise<boolean> {
    var response = await axios.get(`${this.apiBasePath}/api/bottest/queue?botId=${botId}&testFileId=${testFileId}`);
    return response.data;
  }

  async exportTestFile(testFileId:string): Promise<boolean> {
    var response = await axios.get(`${this.apiBasePath}/api/BotTest/files/${testFileId}`);
    return response.data;
  }

  async exportTestExecution(testExecutionId:string): Promise<boolean> {
    var response = await axios.get(`${this.apiBasePath}/api/BotTest/executions/${testExecutionId}`);
    return response.data;
  }

  async deleteTestExecution(testExecutionId:string): Promise<boolean> {
    var response = await axios.delete(
      `${this.apiBasePath}/api/BotTest/executions/${testExecutionId}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  }
  
}

export default new BotTestService();