import { useMsal } from "@azure/msal-react";

export const NavBar: React.FunctionComponent = () => {
  const { accounts, instance } = useMsal();
  const name = accounts[0] && accounts[0].name ? accounts[0].name : ""

  const handleLogout = () => {
      instance.logoutRedirect().catch(e => {
          console.error(e);
      });
  }
  return (
    <>
      <nav className="navbar navbar-expand-md navbar-dark bg-primary fixed-top">
        <a className="navbar-brand ms-Image enelLogo" href="/">
          <img
            src="logo192.png"
            height={32}
            alt="Application Logo"
            title="Application Logo"
            className="ms-Image-image is-loaded ms-Image-image--centerContain ms-Image-image--portrait is-fadeIn image-96"
            style={{paddingRight: '10px'}}
          />
          Bot Test Framework
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mr-auto"></ul>
          <ul className="navbar-nav">
                        <li className="nav-item active">
                            <a className="nav-link">{name}</a>
                        </li>
                        <li className="nav-item active">
                            {accounts[0] && accounts[0].name && (
                                <a className="nav-link" onClick={() => handleLogout()}>Logout</a>
                            )
                            }
                        </li>
                    </ul>
        </div>
      </nav>
    </>
  );
};
