import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  FontIcon,
  FontSizes,
  IColumn,
  PrimaryButton,
  ProgressIndicator,
  SelectionMode,
  Spinner,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  TestExecutionResult,
  TestState,
} from "../../../models/TestExecutionResult";
import BotTestService from "../../../services/BotTestService";
import { formatDate, convertMsToTime } from "../../../utils";

export interface BotTestFileUploadDialogProps {
  items: TestExecutionResult[];
}

export const BotTestHistory: React.FunctionComponent<
  BotTestFileUploadDialogProps
> = (props: BotTestFileUploadDialogProps) => {
  const columns: IColumn[] = [
    {
      key: "icon",
      name: "icon",
      iconName: "",
      isIconOnly: true,
      fieldName: "icon",
      minWidth: 32,
      maxWidth: 32,
      onRender: (item: TestExecutionResult) =>
        item.status === TestState.RUNNING ? (
          <Spinner style={{marginRight:"20px"}}/>
        ) : (
          <FontIcon
            style={{ color: "#007bff" }}
            aria-label="FileComment"
            iconName="FileComment"
          />
        ),
    },

    {
      key: "startDate",
      name: "Start date",
      minWidth: 150,
      maxWidth: 150,
      fieldName: "startDate",
      onRender: (item: TestExecutionResult) => (
        <span>{formatDate(new Date(item.startDate))}</span>
      ),
    },
    {
      key: "duration",
      name: "Duration",
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: TestExecutionResult) => (
        <span>{convertMsToTime(new Date(item.endDate).getTime() - new Date(item.startDate).getTime())}</span>
      ),
    },
    {
      key: "result",
      name: "Result",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: TestExecutionResult) =>
      item.status === TestState.RUNNING ? "-":
        `${item.passedUtterances}/${item.totalUtterances} (${item.successRate?.toFixed(2)}%), Remaining: ${item.errors}`,
    },
    {
      key: "status",
      name: "Status",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item: TestExecutionResult) =>
        item.status === TestState.COMPLETED
          ? "Completed"
          : item.status === TestState.FAILED
          ? "Failed"
          : item.status === TestState.RUNNING
          ?  <ProgressIndicator description={`${item.testedUtterances}/${item.totalUtterances}`} percentComplete={item!.testedUtterances! / item!.totalUtterances!} />
          : item.status === TestState.NEW
          ? "Not started"
          : item.status === TestState.ASSIGNED
          ? "Assigned"
          : item.status === TestState.COMPLETED_WITH_ERRORS
          ? "Completed with errors"
          : "Unknown",
    },

    {
      key: "export",
      name: "Details",
      iconName: "",
      isIconOnly: false,
      fieldName: "icon",
      minWidth: 50,
      maxWidth: 50,
      onRender: (item: TestExecutionResult) => (
        <FontIcon
          style={{
            color: "#007bff",
            fontSize: "16px",
            cursor: "pointer",
            paddingLeft: "10px",
          }}
          aria-label="ExcelDocument"
          iconName="ExcelDocument"
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_API_BASE_URL}/api/BotTest/executions/${item.id}`,
              "_blank"
            );
          }}
        />
      ),
    },
    {
      key: "delete",
      name: "Delete",
      iconName: "",
      isIconOnly: false,
      fieldName: "icon",
      minWidth: 50,
      maxWidth: 50,
      onRender: (item: TestExecutionResult) => (
        <FontIcon
          style={{
            color: "#007bff",
            fontSize: "16px",
            cursor: "pointer",
            paddingLeft: "10px",
          }}
          aria-label="RecycleBin"
          iconName="RecycleBin"
          onClick={() => {
            setSelectedItem(item);
            setHideDeleteTestExecutionModal(false);
          }}
        />
      ),
    }
  ];

  const [executions, setExecutions] = useState<TestExecutionResult[]>([]);
  const [selectedItem, setSelectedItem] = useState<TestExecutionResult>();
  const [hideDeleteTestExecutionModal, setHideDeleteTestExecutionModal] = useState(true);

  const deleteTestExecution = (testExecutionId: string) => {
    BotTestService.deleteTestExecution(testExecutionId)
      .then((result) => {
        toast.success("Test execution deleted successfully");
        setExecutions(executions.filter((execution) => execution.id !== testExecutionId));
      })
      .catch((error) => {
        toast.error("An error occurred while deleting test execution");
      });
  };

  return (
    <div className="container-fluid">
      <span style={{ fontSize: FontSizes.size24 }}>Test execution history</span>
      <DetailsList
        items={props.items}
        columns={columns}
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
      />
    
      <Dialog
      hidden={hideDeleteTestExecutionModal}
      onDismiss={() => setHideDeleteTestExecutionModal(true)}
      dialogContentProps={{
        type: DialogType.normal,
        title: "Are you sure you want to delete this test file?",
      }}
      >
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            deleteTestExecution(selectedItem!.id);
            setHideDeleteTestExecutionModal(true);
          }}
          text="Yes"
        />
        <DefaultButton
          onClick={() => {
            setHideDeleteTestExecutionModal(true);
          }}
          text="No"
        />
      </DialogFooter>
      </Dialog>
    </div>
  );
};
