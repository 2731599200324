import React from "react";
import { IMsalContext, useMsal } from "@azure/msal-react";
import { loginRequest } from "../security/authConfig";
import { IPublicClientApplication } from "@azure/msal-browser";
import { PrimaryButton } from "@fluentui/react";

const handleLogin = (instance: IPublicClientApplication) => {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
};

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
  const { instance, accounts } = useMsal();
  return (
    <PrimaryButton
      text="Sign in"
      allowDisabledFocus
      onClick={() => handleLogin(instance)}
    />
  );
};
