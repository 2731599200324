import "./App.css";
import { NavBar } from "./components/NavBar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { BotSelection } from "./pages/BotSelection/BotSelection";
import { useEffect } from "react";
import { FontSizes, FontWeights, initializeIcons } from "@fluentui/react";
import { BotTest } from "./pages/BotTest/BotTest";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { SignInButton } from "./components/SignInButton";

export const App: React.FunctionComponent = () => {
  useEffect(() => {
    // Update the document title using the browser API
    initializeIcons();
  });

  return (
    <>
    {
     
      console.log(`${process.env.REACT_APP_AAD_ENVIRONMENT} ${process.env.REACT_APP_BUILD_NUMBER}`)
    }
      <AuthenticatedTemplate>
        <div className="container">
          <ToastContainer
            position="bottom-center"
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            autoClose={5000}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <NavBar />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<BotSelection />} />
              <Route path="/test" element={<BotTest />} />
            </Routes>
          </BrowserRouter>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <NavBar />
        <div className="center">
          <span
            style={{
              fontSize: FontSizes.size32,
              fontWeight: FontWeights.regular as number,
            }}
          >
            You are not signed in! Please sign in.
          </span>
          <br></br>
          <SignInButton />
        </div>
      </UnauthenticatedTemplate>
    </>
  );
};
