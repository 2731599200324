import {
  DocumentCard,
  DocumentCardActions,
  DocumentCardActivity,
  DocumentCardDetails,
  DocumentCardTitle,
  DocumentCardType,
  FontSizes,
  FontWeights,
  IDocumentCardActivityPerson,
  initializeIcons,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BotConfig } from "../../models/BotConfig";
import BotTestService from "../../services/BotTestService";

export const BotSelection: React.FunctionComponent = () => {
  const [botsConfigs, setBotsConfigs] = useState<BotConfig[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    initializeIcons();
    setIsLoading(true);
    BotTestService.getBotsConfigs()
      .then((botsConfigs) => {
        setIsLoading(false);
        setBotsConfigs(botsConfigs);
      })
      .catch((error) => {
        toast.error("An error occurred while loading bots");
        setIsLoading(false);
      });
  }, []);
  let navigate = useNavigate();

  return (
    <div className="container">
      <span style={{ fontSize: FontSizes.size32 }}>
        Welcome to Bot Test Framework
      </span>
      <br />
      <span style={{ fontSize: FontSizes.size20, paddingBottom: "10px" }}>
        Please select one bot to start testing:
      </span>
      <br />

      {isLoading && (
        <Spinner size={SpinnerSize.large} label="Loading, please wait" />
      )}

      {!isLoading && (
        <div className="row">
          {botsConfigs.map((bot: BotConfig, index: number) => {
            return (
              <div className="col-lg-4">
                <DocumentCard
                  style={{ margin: "10px" }}
                  type={DocumentCardType.normal}
                  onClick={() => {
                    navigate({
                      pathname: "/test",
                      search: `?id=${bot.id}&name=${bot.name}`,
                    });
                  }}
                >
                  <DocumentCardDetails>
                    <DocumentCardActivity
                      activity={`Environment: ${bot.environment}`}
                      people={[
                        {
                          name: bot.name,
                          profileImageSrc: bot.iconPath,
                        },
                      ]}
                    />
                  </DocumentCardDetails>
                </DocumentCard>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
