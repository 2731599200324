export interface TestExecutionResult {
  id: string;
  fileName: string;
  startDate: Date;
  endDate: Date;
  totalUtterances?: number;
  passedUtterances?: number;
  testedUtterances?: number;
  errors?: number;
  successRate?: number;
  status: TestState;
}

export enum TestState {
  DEFAULT,
  NEW,
  ASSIGNED,
  RUNNING,
  COMPLETED,
  COMPLETED_WITH_ERRORS,
  FAILED
}
