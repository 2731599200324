import {
  ChoiceGroup,
  DefaultButton,
  Dialog,
  DialogFooter,
  Modal,
  PrimaryButton,
} from "@fluentui/react";
import { useState } from "react";
import { BotTestFileDetails } from "../../../models/BotTestFileDetails";
import BotTestService from "../../../services/BotTestService";

export interface BotTestFileUploadDialogProps {
  botName:string;
  onClose: () => void;
  onUpload: (result: boolean,fileDetail:BotTestFileDetails | null) => void;
}

export const BotTestFileUploadDialog: React.FunctionComponent<BotTestFileUploadDialogProps> = (props) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedFileName, setSelectedFileName] = useState<string>();

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const file = event.target.files[0];
      setSelectedFile(file);
      const name = event.target.files[0].name;
      setSelectedFileName(name);
      console.log(file);
    }
  };




  const onFileUpload = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile!);
    formData.append("fileName", selectedFileName!);
    BotTestService.uploadTestFile(props.botName,formData)
      .then((result) => {
        props.onUpload(true,result);
      })
      .catch((error) => {
        props.onUpload(false,null);
      });
  };

  return (
    <Dialog hidden={false}>
      <h3>Select a file to upload</h3>
      <br />
      <div>
        <input type="file" accept=".csv,.json" onChange={onFileChange} />
        <br />
      </div>
      <DialogFooter>
        <DefaultButton text="Cancel" onClick={() => props.onClose()}/>
        <PrimaryButton text="Upload" onClick={onFileUpload} />
      </DialogFooter>
    </Dialog>
  );
};
